<template>
  <u-dashboard-toolbar>
    <template #left>
      <span class="px-4 py-2">
        Today is
        <strong class="font-semibold">
          {{ app.data.time.format('dddd, MMMM Do') }} </strong>.
      </span>
    </template>
  </u-dashboard-toolbar>
  <u-dashboard-panel-content
    ref="dashboardPanelContent"
    :class="{
      'grid': true,
      'lg:grid-cols-[1fr_auto]': true,
      'w-full': true,
      'h-fit': true,
      'gap-4': true,
      'pb-4': true,
    }"
  >
    <div
      v-if="nonDraftEvents.length"
      :class="{
        'grid': true,
        'grid-cols-1': true,
        'gap-4': true,
        'w-full': true,
        'h-fit': true,
        'pb-16': true,
        'sm:grid-cols-2': true,
        '2xl:grid-cols-3': true,
      }"
    >
      <lazy-client-only>
        <app-event
          v-for="event in nonDraftEvents"
          :key="event.id"
          :event="event"
        />
      </lazy-client-only>
    </div>
    <app-banner v-else>
      <p>There aren't any events. Be the first 🤠 in town!</p>
    </app-banner>
    <app-map class="sticky top-0 h-screen max-w-[10vw]" />
  </u-dashboard-panel-content>
</template>

<script lang="ts" setup>
useHead({
  title: 'The Show Portal',
})

const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const auth = useAuth()
const data = useData()
const dashboardPanelContent = ref<HTMLElement | null>(null)
const loadRange = 50
const count = ref(0)
const startIndex = ref(0)
const endIndex = ref(startIndex.value + 50 - 1)
const nonDraftEvents = computed(() => {
  return Array.from(data.events.values())
    .filter((event) => !event.metadata.is_draft)
    .sort((a, b) => {
      const aIsLive = app.data.time.isAfter(a.start_at)
        && app.data.time.isBefore(a.end_at)
      const bIsLive = app.data.time.isAfter(b.start_at)
        && app.data.time.isBefore(b.end_at)
      const aEndPassed = app.data.time.isAfter(a.end_at)
      const bEndPassed = app.data.time.isAfter(b.end_at)

      if (aIsLive && !bIsLive) {
        return -1
      }
      else if (!aIsLive && bIsLive) {
        return 1
      }
      else if (aEndPassed && !bEndPassed) {
        return 1
      }
      else if (!aEndPassed && bEndPassed) {
        return -1
      }

      return useTime(a.start_at).diff(b.start_at)
    })
})

function incrementRange() {
  const nextStart = endIndex.value + 1

  if (count.value > nextStart) {
    startIndex.value = nextStart
    endIndex.value += loadRange
  }
}

const { data: initialEvents, count: eventCount } = await supabase
  .from('events')
  .select('*', { count: 'exact' })
  .eq('metadata->is_draft', false)
  .order('start_at', { ascending: true })
  .order('end_at', { ascending: true })
  .range(startIndex.value, endIndex.value)
  .returns<PortalEvent[]>()

if (typeof eventCount === 'number') {
  count.value = eventCount
}

data.cacheMany('events', initialEvents)

incrementRange()

useInfiniteScroll(
  dashboardPanelContent,
  async () => {
    const { data: nextEvents } = await supabase
      .from('events')
      .select()
      .eq('metadata->is_draft', false)
      .order('start_at', { ascending: true })
      .order('end_at', { ascending: true })
      .range(startIndex.value, endIndex.value)
      .returns<PortalEvent[]>()

    data.cacheMany('events', nextEvents)

    incrementRange()
  },
  {
    distance: 300,
    canLoadMore: () => count.value > data.events.size,
  },
)

watch(
  () => auth.user?.name_first,
  (value) => {
    app.data.title = app.data.randomTitle

    if (value?.length) {
      app.data.title += `, ${value}`
    }
  },
  { immediate: true },
)
</script>
