<template>
  <div
    :class="{
      'flex': true,
      'items-center': true,
      'justify-center': true,
      'justify-items-center': true,
      'min-w-[25vw]': true,
      'w-full': true,
      'bg-white dark:bg-gray-900': true,
      'border': true,
      'border-gray-300 dark:border-gray-700': true,
      'box-border': true,
      'rounded-md': true,
      'shadow-sm': true,
      'overflow-hidden': true,
    }"
  >
    <div
      ref="mapElement"
      class="size-full"
    />
  </div>
</template>

<script lang="ts" setup>
type PartialPortalEvent = {
  id: string
  name: string
  end_at: string
  coords: {
    lat: number
    lng: number
  }
}

const { $google } = useNuxtApp()
const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const auth = useAuth()
const eventLocations = new Map<string, PartialPortalEvent>()
const mapElement = ref<HTMLDivElement>()
const map = ref()
const center = ref({
  lat: 33.7673477,
  lng: -84.4901697,
})
const eventIdsOnMap = new Set<string>()

function addPin(partialEvent: PartialPortalEvent) {
  if (
    !map.value
    || eventIdsOnMap.has(partialEvent.id)
    || app.data.time.isAfter(partialEvent.end_at)
  ) {
    return
  }

  new $google.Marker({
    position: {
      lat: partialEvent.coords.lat,
      lng: partialEvent.coords.lng,
    },
    map: map.value,
    icon: {
      url: app.state.isDark ? '/map-pin-dark.png' : '/map-pin.png',
      size: new google.maps.Size(32, 32),
    },
    title: partialEvent.name,
    optimized: true,
    draggable: false,
    animation: google.maps.Animation.DROP,
  }).addListener('click', () => {
    navigateTo(`/event/${useShortId(partialEvent.id)}`)
  })

  eventIdsOnMap.add(partialEvent.id)
}

function renderMap() {
  if (mapElement.value) {
    eventIdsOnMap.clear()

    map.value = new $google.Map(mapElement.value, {
      mapId: app.state.isDark ? 'efefe3e704a20abf' : '3c06cfb3575b11b0',
      center: center.value,
      zoom: 5,
      disableDefaultUI: true,
    })

    for (const [_, event] of eventLocations) {
      addPin(event)
    }
  }
}

watch([() => auth.user?.metadata.lat, () => auth.user?.metadata.lng], (value) => {
  if (typeof value[0] === 'number' && typeof value[1] === 'number') {
    center.value = {
      lat: value[0],
      lng: value[1],
    }
  }
  else {
    center.value = {
      lat: 33.7673477,
      lng: -84.4901697,
    }
  }

  if (map.value) {
    map.value.setCenter(center.value)
  }
})

watch(
  () => eventLocations,
  (value) => {
    for (const [, event] of value) {
      addPin(event)
    }
  },
  {
    deep: true,
  },
)

watch(() => app.state.isDark, renderMap)

onMounted(async () => {
  // TODO: Pickup any events that are inserted after the initial fetch
  const { data: events } = await supabase
    .from('events')
    .select('id, name, metadata')
    .eq('metadata->is_draft', false)
    .gte('metadata->lat', -90)
    .lte('metadata->lat', 90)
    .gte('metadata->lng', -180)
    .lte('metadata->lng', 180)
    .returns<PortalEvent[]>()

  for (const event of events ?? []) {
    eventLocations.set(event.id, {
      id: event.id,
      name: event.name,
      end_at: event.end_at,
      coords: {
        lat: event.metadata.lat as number,
        lng: event.metadata.lng as number,
      },
    })
  }

  renderMap()
})
</script>

<style lang="scss" scoped>
:deep(div) {
  border: none !important;
}

div {
  display: none;
  max-height: 712px;
}

@media screen and (max-height: 859px) {
  div {
    max-height: calc(100vh - 138px);
  }
}

@media screen and (min-height: 500px) and (min-width: 1024px) {
  div {
    display: flex;
  }
}
</style>
